/* kde zacina */
.summary--enter {
  transform: translateX(100vw);
}

.summary--enter-active {
  transform: translateX(0);
  transition: transform 1000ms;
}

/* kde skonci */
.summary--enter-done {
  transform: translateX(0);
}

/*

Exit
*/

/* kde zacina */
.summary--exit {
  transform: translateX(0);
}

.summary--exit-active {
  transform: translateX(100vw);
  transition: transform 1000ms;
}

/* kde skonci */
.summary--exit-done {
  transform: translateX(100vw);
}
