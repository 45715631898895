.orderPage--appear {
  opacity: 0;
  /* transform: translateX(-100vw); */
}

.orderPage--appear-active {
  /* transform: translateX(0); */
  opacity: 1;
  transition: opacity 1000ms;
  /* transition: transform 1000ms; */
}

.orderPage--appear-done {
  opacity: 1;
  /* transform: translateX(0); */
}

/* .orderPage--exit {
  transform: translateX(0);
}

.orderPage--exit-active {
  transform: translateX(-100vw);
  transition: transform 1000ms;
}

.orderPage--exit-done {
  transform: translateX(-100vw);
} */
