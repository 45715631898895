.quill {
  color: black;
  background: whitesmoke;
}

.ql-picker-options {
  background-color: lightgray !important;
}

.ql-toolbar {
  display: flex;
  align-items: center;
}

.ql-toolbar span > button {
  margin: 0;
}
.ql-editor {
  background: white;
  min-height: 10rem;
}
