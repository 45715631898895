.homePage--appear {
  opacity: 0;
}

.homePage--appear-active {
  opacity: 1;
  transition: opacity 1000ms;
}

.homePage--appear-done {
  opacity: 1;
}
