/*
Slide from left
*/

.slideFromLeft-enter {
  transform: translateX(-100vw);
  opacity: 0;
}

.slideFromLeft-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 300ms linear 300ms;
}

.slideFromLeft-exit {
  transform: translateX(0);
  opacity: 1;
}

.slideFromLeft-exit-active {
  transform: translateX(100vw);
  opacity: 0;
  transition: all 300ms linear;
}

/*
Slide from right
*/

.slideFromRight-enter {
  transform: translateX(100vw);
  opacity: 0;
}

.slideFromRight-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 300ms linear 300ms;
}

.slideFromRight-exit {
  transform: translateX(0);
  opacity: 1;
}

.slideFromRight-exit-active {
  transform: translateX(-100vw);
  opacity: 0;
  transition: all 300ms linear;
}

/*
fade
*/
.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}
.fade-enter-active {
  opacity: 1;
  transform: translateX(0);
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
}
